import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const Card = ({ excerpt, id, image, title }) => {
  return (
    <article className="text-center">
      <Link aria-label={title} className="block mb-4 shadow" to={`/${id}`}>
        <Img alt="" fluid={image.childImageSharp.fluid} />
      </Link>
      <h1 className="border-t border-b border-gray-300 font-normal mb-2 leading-relaxed text-base">
        {title}
      </h1>
      {excerpt && <p className="text-sm">{excerpt}</p>}
    </article>
  )
}

Card.propTypes = {
  id: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  excerpt: PropTypes.string,
  title: PropTypes.string.isRequired,
}

export default Card
