import React from 'react'
import {graphql, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Card from '../components/card'
import Img from "gatsby-image"
import Callout from "../components/callout"

const CorporatePage = ( {data} ) => {
	const {allClientJson} = data
	return (
		<Layout>
			<SEO
				description="LA Art Exchange offers comprehensive art project management from initial consultation to installation. Serving the Santa Monica and Los Angeles area"
				title="Corporate Art Consulting in LA & Santa Monica"
			/>
			<main className="container lg:pt-12">
				<div className="lg:flex mb-12 lg:mb-20">
					<div className="lg:w-1/2 flex flex-col p-4 lg:p-8 bg-gray-300 text-purple-500">
						<h1 className="text-center lg:text-left text-xl lg:text-2xl my-4">
							Corporate Planning
						</h1>
						<p className="text-sm lg:text-base mb-6">
							<strong>
								Comprehensive project management from initial consultation to
								installation
							</strong>
						</p>
						<p className="text-sm lg:text-base mb-6">
							Complex corporate projects are managed carefully by our
							experienced design staff. We can curate the art of your walls by
							either working with your current collection, procuring new artwork
							or organizing and printing company assets.
						</p>
					</div>
					<div className="lg:w-1/2 flex flex-col p-4 lg:p-8 bg-purple-300 text-white">
						<div className="max-w-2xl mx-auto">
							<h2 className="text-center lg:text-left text-xl lg:text-2xl my-4 lg:mb-10">
								If You Can Think Of It.
								<br/>
								<span className="font-normal">We Can Do It</span>
							</h2>
							<ul className="list-disc pl-4 text-sm lg:text-base mb-6 lg:mb-10">
								<li className="mb-1">On-site project management</li>
								<li className="mb-1">On-site design consultation</li>
								<li className="mb-1">Delivery and installation services</li>
								<li className="mb-1">High quality printing</li>
								<li className="mb-1">Awards framing</li>
								<li className="mb-1">Custom framing for 3-d display</li>
								<li className="mb-1">Employee discount for personal framing</li>
							</ul>
							<Link className="btn btn-white mb-6" to="/contact">
								Make an appointment{` `}
								<span className="hidden lg:inline">today</span>
							</Link>
						</div>
					</div>
				</div>
				<div className="flex items-center mb-6 lg:mb-12">
					<div
						className="bg-purple-300 flex-1 mr-2"
						role="presentation"
						style={{height: `1px`}}
					></div>
					<h2 className="text-center text-purple-300 text-lg lg:text-xl">
						Our Clients
					</h2>
					<div
						className="bg-purple-300 flex-1 ml-2"
						role="presentation"
						style={{height: `1px`}}
					></div>
				</div>
				<ul className="grid gap-10 md:grid-cols-2 md:gap-16 mb-12">
					{allClientJson &&
					allClientJson.nodes.map( ( {slug, name, cover} ) => (
						<li key={slug}>
							<Card id={slug} image={cover} title={name}/>
						</li>
					) )}
				</ul>
			</main>
			<aside>
				<div className=" container">
					<li className="bg-tan flex justify-center items-center py-10 px-4 lg:px-0">
						<div className="max-w-sm relative">
							<h2 className="text-base lg:text-lg mb-2">Delivery</h2>
							<p className="text-xs mb-6">
								Your art and your time are valuable. We have a dedicated driver
								on our team who can help facilitate your framing needs. Whether
								you need help bringing in your large painting, or if visiting
								our showroom doesn’t fit into your schedule, we can pick up your
								project, design in person or through email, and then deliver
								back to you when finished.
							</p>
							<Img
								className="lg:bottom-0 lg:-right-40 absolute mx-auto lg:-mt-24 lg:mr-10"
								fluid={data.deliveryTruck.childImageSharp.fluid}
								style={{width: `191px`, height: `90px`}}
							/>
						</div>
					</li>
				</div>
				<Callout/>
			</aside>
		</Layout>
	)
}

export const query = graphql`
  query {
    allClientJson {
      nodes {
        name
        description
        slug
        cover {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    deliveryTruck: file(relativePath: { eq: "delivery-truck.png" }) {
      childImageSharp {
        fluid(maxWidth: 382, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    projectManagement: file(relativePath: { eq: "project-management.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    instagram: file(relativePath: { eq: "sample-remove-later/instagram.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CorporatePage
